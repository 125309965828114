<template>
    <footer class="footer-three footer-grey">
        <div class="footer-top p-bottom-25">
            <div class="container">
                <div class="row">
                    <div class="col-lg-2 col-sm-6">
                        <div class="widget widget_pages">
                            <h2 class="widget-title">Company Info</h2>
                            <ul class="list-unstyled">
                                <li class="page-item"><a href="">About Us</a></li>
                                <li class="page-item"><a href="">Conact Us</a></li>
                                <li class="page-item"><a href="">Our Listings</a></li>
                                <li class="page-item"><a href="">Our Pricings</a></li>
                                <li class="page-item"><a href="">Support</a></li>
                                <li class="page-item"><a href="">Privacy Policy</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-3 d-flex justify-content-lg-center  col-sm-6">
                        <div class="widget widget_pages">
                            <h2 class="widget-title">Helpful Links</h2>
                            <ul class="list-unstyled">
                                <li class="page-item"><a href="">Join Direo</a></li>
                                <li class="page-item"><a href="">Sign In</a></li>
                                <li class="page-item"><a href="">How it Work</a></li>
                                <li class="page-item"><a href="">Advantages</a></li>
                                <li class="page-item"><a href="">Direo App</a></li>
                                <li class="page-item"><a href="">Packages</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6">
                        <div class="widget widget_social">
                            <h2 class="widget-title">Connect with Us</h2>
                            <ul class="list-unstyled social-list">
                                <li><a href=""><span class="mail"><i class="la la-envelope"></i></span> Contact Support</a>
                                </li>
                                <li><a href=""><span class="twitter"><i class="fab fa-twitter"></i></span> Twitter</a></li>
                                <li><a href=""><span class="facebook"><i class="fab fa-facebook-f"></i></span> Facebook</a>
                                </li>
                                <li><a href=""><span class="instagram"><i class="fab fa-instagram"></i></span> Instagram</a>
                                </li>
                                <li><a href=""><span class="gplus"><i class="fab fa-google-plus-g"></i></span> Google+</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <div class="widget widget_text">
                            <h2 class="widget-title">Direo on Mobile</h2>
                            <div class="textwidget">
                                <p>Download the Direo app today so you can find your events anytime, anywhere.</p>
                                <ul class="list-unstyled store-btns">
                                    <li><a href="" class="btn-gradient btn-gradient-two btn btn-md btn-icon icon-left"><span
                                                class="fab fa-apple"></span> App Store</a></li>
                                    <li><a href="" class="btn btn-dark btn-md btn-icon btn-icon"><span
                                                class="fab fa-android"></span> Google
                                            Play</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="footer-bottom">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="footer-bottom--content"> <a href="" class="footer-logo"><img src="img/logo.png"
                                    alt=""></a>
                            <p class="m-0 copy-text">©2019 Direo. Made with <span class="la la-heart-o"></span> by <a
                                    href="">Aazztech</a></p>
                            <ul class="list-unstyled lng-list">
                                <li><a href="">English</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
    </footer>
</template>