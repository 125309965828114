<template>
    <section class="header-breadcrumb bgimage overlay overlay--dark">
        <div class="bg_image_holder">
            <img src="img/breadcrumb1.jpg" alt="">
        </div>
        <div class="mainmenu-wrapper">
            <div class="menu-area menu1 menu--light">
                <div class="top-menu-area">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="menu-fullwidth">
                                    <div class="logo-wrapper order-lg-0 order-sm-1">
                                        <div class="logo logo-top">
                                            <a href="index.html">
                                                <img src="logo.png" alt="logo image" class="img-fluid">
                                            </a>
                                        </div>
                                    </div>
                                    <div class="menu-container order-lg-1 order-sm-0">
                                        <div class="d_menu">
                                            <NavbarSection />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="breadcrumb-wrapper content_above">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 text-center">
                        <h1 class="page-title">All Listing</h1>
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="#">Home</a></li>
                                <li class="breadcrumb-item active" aria-current="page">All Listings</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="all-listing-wrapper section-bg">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="atbd_generic_header">
                        <div class="atbd_generic_header_title">
                            <h4>All Items</h4>
                            <p>Total Listing Found: {{ productStore.total }}</p>
                        </div>
                        <div class="atbd_listing_action_btn btn-toolbar align-items-center" role="toolbar">
                            <div class="dropdown">
                                <a class="action-btn dropdown-toggle" href="" role="button" id="dropdownMenuLink2"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Sort by <span
                                        class="caret"></span></a>
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuLink2">
                                    <a class="dropdown-item" @click="updateSort('name', 'asc')">A to Z ( name )</a>
                                    <a class="dropdown-item" @click="updateSort('name', 'desc')">Z to A ( name )</a>
                                    <a class="dropdown-item" @click="updateSort('created_at', 'desc')">Latest listings</a>
                                    <a class="dropdown-item" @click="updateSort('created_at', 'asc')">Oldest listings</a>
                                    <a class="dropdown-item" @click="updateSort('price', 'desc')">Price ( low to high )</a>
                                    <a class="dropdown-item" @click="updateSort('price', 'asc')">Price ( high to low )</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 listing-items">
                    <div class="row">
                        <div class="col-lg-4 order-1 order-lg-0 mt-5 mt-lg-0">
                            <div class="listings-sidebar">
                                <div class="search-area default-ad-search">
                                    <form>
                                        <div class="form-group">
                                            <input type="text" v-model="search" placeholder="What are you looking for?"
                                                class="form-control">
                                        </div>
                                        <div class="form-group">
                                            <div class="select-basic">
                                                <select class="form-control ad_search_category" v-model="category">
                                                    <option value="">Select Category</option>
                                                    <option v-for="category in categories" :key="category.id"
                                                        :value="category.id">
                                                        {{ category.name }}
                                                    </option>

                                                    <!-- <option v-for="category in categories" :key="category.id"
                                                        :value="category.id">{{ category.name }}</option>
                                                    <option>&nbsp;&nbsp; Food</option> -->
                                                    <!-- <optgroup v-for="category in categories" :key="category.name" :label="category.name">
                                                            <option v-for="subCategory in category.subCategories" :key="subCategory" :value="subCategory">{{ subCategory }}</option>
                                                        </optgroup> -->
                                                    <!-- <option value="">{{ category.name }}</option>
                                                    <br /> -->
                                                    <!-- <option v-for="childCategory in category.children"
                                                        :key="childCategory.id" :value="childCategory.id">
                                                        - {{ childCategory.name }}
                                                    </option> -->

                                                    <!-- <option>&nbsp;&nbsp; Lunch Pack</option>
                                                    <option>Places</option>
                                                    <option>&nbsp;&nbsp; Travel</option>
                                                    <option>&nbsp;&nbsp; Booking</option>
                                                    <option>&nbsp;&nbsp; Hotel</option>
                                                    <option>&nbsp;&nbsp; Location</option>
                                                    <option>Shopping</option>
                                                    <option>&nbsp;&nbsp; Accessories</option>
                                                    <option>&nbsp;&nbsp; Clothing</option>
                                                    <option>&nbsp;&nbsp; Gadgets</option> -->
                                                </select>
                                            </div>
                                        </div>

                                        <!-- <div class="form-group p-bottom-10">
                                            <div class="price-range rs-primary">
                                                <p class="d-flex justify-content-between">
                                                    <span>Price Range: </span>
                                                    <span class="amount"></span>
                                                </p>
                                                <div class="slider-range"></div>
                                            </div>
                                        </div> -->

                                        <div class="filter-checklist">
                                            <h5>Filter by Features</h5>
                                            <div class="checklist-items feature-checklist">
                                                <div
                                                    class="custom-control custom-checkbox checkbox-outline checkbox-outline-primary">
                                                    <input type="radio" v-model="feature" name="feature" value="1"
                                                        class="custom-control-input" id="tag9" checked>
                                                    <label class="custom-control-label" for="tag9">Yes</label>
                                                </div>
                                                <div
                                                    class="custom-control custom-checkbox checkbox-outline checkbox-outline-primary">
                                                    <input type="radio" v-model="feature" name="feature" value="0"
                                                        class="custom-control-input" id="tag10">
                                                    <label class="custom-control-label" for="tag10">No</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="filter-checklist">
                                            <h5>Filter by Ratings</h5>
                                            <div class="sort-rating">
                                                <div
                                                    class="custom-control custom-checkbox checkbox-outline checkbox-outline-primary">
                                                    <input type="radio" v-model="rating" name="rating" value="5"
                                                        class="custom-control-input" id="customCheck7" checked>
                                                    <label class="custom-control-label" for="customCheck7">
                                                        <span class="active"><i class="la la-star"></i></span>
                                                        <span class="active"><i class="la la-star"></i></span>
                                                        <span class="active"><i class="la la-star"></i></span>
                                                        <span class="active"><i class="la la-star"></i></span>
                                                        <span class="active"><i class="la la-star"></i></span>
                                                    </label>
                                                </div>
                                                <div
                                                    class="custom-control custom-checkbox checkbox-outline checkbox-outline-primary">
                                                    <input type="radio" v-model="rating" name="rating" value="4"
                                                        class="custom-control-input" id="customCheck8">
                                                    <label class="custom-control-label" for="customCheck8">
                                                        <span class="active"><i class="la la-star"></i></span>
                                                        <span class="active"><i class="la la-star"></i></span>
                                                        <span class="active"><i class="la la-star"></i></span>
                                                        <span class="active"><i class="la la-star"></i></span>
                                                        <span><i class="la la-star"></i></span>
                                                    </label>
                                                </div>
                                                <div
                                                    class="custom-control custom-checkbox checkbox-outline checkbox-outline-primary">
                                                    <input type="radio" v-model="rating" name="rating" value="3"
                                                        class="custom-control-input" id="customCheck9">
                                                    <label class="custom-control-label" for="customCheck9">
                                                        <span class="active"><i class="la la-star"></i></span>
                                                        <span class="active"><i class="la la-star"></i></span>
                                                        <span class="active"><i class="la la-star"></i></span>
                                                        <span><i class="la la-star"></i></span>
                                                        <span><i class="la la-star"></i></span>
                                                    </label>
                                                </div>
                                                <div
                                                    class="custom-control custom-checkbox checkbox-outline checkbox-outline-primary">
                                                    <input type="radio" v-model="rating" name="rating" value="2"
                                                        class="custom-control-input" id="customCheck10">
                                                    <label class="custom-control-label" for="customCheck10">
                                                        <span class="active"><i class="la la-star"></i></span>
                                                        <span class="active"><i class="la la-star"></i></span>
                                                        <span><i class="la la-star"></i></span>
                                                        <span><i class="la la-star"></i></span>
                                                        <span><i class="la la-star"></i></span>
                                                    </label>
                                                </div>
                                                <div
                                                    class="custom-control custom-checkbox checkbox-outline checkbox-outline-primary">
                                                    <input type="radio" v-model="rating" name="rating" value="1"
                                                        class="custom-control-input" id="customCheck11">
                                                    <label class="custom-control-label" for="customCheck11">
                                                        <span class="active"><i class="la la-star"></i></span>
                                                        <span><i class="la la-star"></i></span>
                                                        <span><i class="la la-star"></i></span>
                                                        <span><i class="la la-star"></i></span>
                                                        <span><i class="la la-star"></i></span>
                                                    </label>
                                                </div>
                                                <!-- <div
                                                    class="custom-control custom-checkbox checkbox-outline checkbox-outline-primary">
                                                    <input type="radio" v-model="rating" name="rating" value="0"
                                                        class="custom-control-input" id="customCheck12">
                                                    <label class="custom-control-label" for="customCheck12">
                                                        <span><i class="la la-star"></i></span>
                                                        <span><i class="la la-star"></i></span>
                                                        <span><i class="la la-star"></i></span>
                                                        <span><i class="la la-star"></i></span>
                                                        <span><i class="la la-star"></i></span>
                                                    </label>
                                                </div> -->
                                            </div>
                                        </div>
                                        <!-- <a href=""
                                            class="btn btn-gradient btn-gradient-two btn-block btn-icon icon-right m-top-40">Search
                                            Filter <span class="la la-long-arrow-right"></span>
                                        </a> -->
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-8 order-0 order-lg-1">
                            <div class="row">
                                <div class="col-lg-12" v-for="product in products.data" :key="product.id">
                                    <div class="atbd_single_listing atbd_listing_list">
                                        <article class="atbd_single_listing_wrapper">
                                            <figure class="atbd_listing_thumbnail_area">
                                                <div class="atbd_listing_image">
                                                    <a href="">
                                                        <img :src="product.image" alt="product image">
                                                    </a>
                                                </div>
                                                <div class="atbd_thumbnail_overlay_content">
                                                    <ul class="atbd_upper_badge">
                                                        <li>
                                                            <span class="atbd_badge atbd_badge_featured"
                                                                v-if="product.feature">Featured</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </figure>
                                            <div class="atbd_listing_info">
                                                <div class="atbd_content_upper">
                                                    <h4 class="atbd_listing_title">
                                                        <span>{{ product.name }}</span>
                                                    </h4>
                                                    <div class="atbd_listing_meta">
                                                        <span class="atbd_meta atbd_listing_rating">
                                                            {{ product.ratings }}
                                                            <i class="la la-star"></i>
                                                        </span>
                                                        <span class="atbd_meta atbd_listing_price">${{ product.price
                                                        }}</span>
                                                        <span class="atbd_meta atbd_badge_open">
                                                            <a :href="product.referral_link" target="_blank" rel="noopener noreferrer">Go Shop</a>
                                                        </span>
                                                    </div>
                                                    <div class="atbd_listing_data_list">
                                                        <ul>
                                                            <li>
                                                                <p>
                                                                    <span class="la la-map-marker"></span>
                                                                    Los Angeles, United States
                                                                </p>
                                                            </li>
                                                            <li>
                                                                <p>
                                                                    <span class="la la-phone"></span>
                                                                    (415) 796-3633
                                                                </p>
                                                            </li>
                                                            <li>
                                                                <p>
                                                                    <span class="la la-calendar-check-o"></span>
                                                                    Posted {{ product.created_at }}
                                                                </p>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="atbd_listing_bottom_content">
                                                    <div class="atbd_content_left">
                                                        <div class="atbd_listing_category">
                                                            <a href="">
                                                                <span class="la la-amazon"></span>
                                                                {{ product.category.name }}
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <ul class="atbd_content_right">
                                                        <li class="atbd_count"><span class="la la-list"></span>{{ product.quantity }}</li>
                                                        <!-- <li class="atbd_save">
                                                            <span class="la la-heart-o"></span>
                                                        </li> -->
                                                        <li>
                                                            <div class="atbd_author atbd_author--thumb">
                                                                <a href="">
                                                                    <img :src="product.category.image" alt="Author Image">
                                                                    <span class="custom-tooltip">Jeffery A, Owner</span>
                                                                </a>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </article>
                                    </div>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-lg-12">
                                    <nav class="navigation pagination d-flex justify-content-end" role="navigation">
                                        <div class="nav-links">
                                            <Bootstrap5Pagination :data="products"
                                                @pagination-change-page="page => productStore.fetchProducts(page, category, search, feature, rating, order_column, order_direction)" />
                                        </div>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
import NavbarSection from '@/components/Partials/Navbar/NavbarSection.vue';
import { onMounted, computed, ref, watchEffect } from "vue";
import { useProductStore } from "../store/product-store";
import { useCategoryStore } from "../store/category-store";
import { Bootstrap5Pagination } from 'laravel-vue-pagination';
// import { storeToRefs } from 'pinia';

const search = ref("");
const feature = ref("");
const rating = ref("");
const category = ref("");
const order_column = ref("created_at");
const order_direction = ref("desc");


const categoryStore = useCategoryStore();
const categories = computed(() => {
    return categoryStore.categories;
});

const productStore = useProductStore();

onMounted(() => {
    productStore.fetchProducts();
    categoryStore.fetchCategories();
});


const updateSort = (order, direction) => {
    order_column.value = order;
    order_direction.value = direction;
};


const combinedSearchProperties = computed(() => ({
    category: category.value,
    search: search.value,
    feature: feature.value,
    rating: rating.value,
    order_column: order_column.value,
    order_direction: order_direction.value,
}));

watchEffect(() => {
    const { category, search, feature, rating, order_column, order_direction } = combinedSearchProperties.value;
    console.log("Search for products: " + search);
    productStore.fetchProducts(1, category, search, feature, rating, order_column, order_direction);
});


const products = computed(() => {
    return productStore.products;
});

// const fetchProducts = () => {
//     try {
//         return  ;
//     } catch (error) {
//         console.error(error);
//     }
// };

// const products = fetchProducts();

// console.log("fetchProducts productData ::::", products)

// watch((category, search, feature, rating), (category, search, feature, rating) => {
//     console.log("Search for products: " + search)
//     productStore.fetchProducts(1, category, search, feature, rating);
// });


// const state = reactive({ categoryId: category });
// console.log("search : " + search.value)
// console.log("feature : " + feature.value)
// console.log("rating : " + rating.value)
// console.log("category : " + category.value)
// productStore.fetchProducts(category.value, search.value, feature.value, rating.value);

// console.log("Productllllllllllllllllllllll::", products)

// const products = computed(() => {
//     console.log("sdsds :", productStore.products)
//     return state.categoryId === 'all'
//         ? productStore.products.data
//         : productStore.products.data.filter((product) => product.category_id === state.categoryId);

//         // return  productStore.products.data.filter((product) =>
//         //     product.name.toLowerCase().includes(search.value.toLowerCase())
//         // );
// });


// const products = computed(() => {
//     return productStore.filteredProducts({
//         category: category.value,
//         search: search.value,
//         feature: feature.value,
//         rating: rating.value,
//     });
// });


</script>
