<template>
    <div class="directory_content_area">
        <div class="container">
            <div class="row">
                <div class="col-lg-10 offset-lg-1">
                    <div class="search_title_area">
                        <h2 class="title">Search By Category</h2>
                    </div>
                    <form action="/" class="search_form">
                        <div class="atbd_seach_fields_wrapper">
                            <div class="single_search_field search_query">
                                <input class="form-control search_fields" type="text"
                                    placeholder="What are you looking for?">
                            </div>
                            <!-- <div class="single_search_field search_category">
                                <select class="search_fields" id="at_biz_dir-category">
                                    <option value="">Select a category</option>
                                    <option value="automobile">Automobile</option>
                                    <option value="education">Education</option>
                                    <option value="event">Event</option>
                                </select>
                            </div>
                            <div class="single_search_field search_location">
                                <select class="search_fields" id="at_biz_dir-location">
                                    <option value="">Select a location</option>
                                    <option value="ab">AB Simple</option>
                                    <option value="australia">Australia</option>
                                    <option value="australia-australia">Australia</option>
                                </select>
                            </div> -->
                            <div class="atbd_submit_btn">
                                <button type="submit" class="btn btn-block btn-gradient btn-gradient-one btn-md btn_search">Search</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>