<template>
    <section class="testimonial-wrapper section-padding--bottom">
        <div class="container">
            <div class="row mt-5">
                <div class="col-lg-12 mt-5">
                    <div class="section-title mt-5">
                        <h2>Trusted By Over 4000+ Users</h2>
                        <p>Here is what people say about Direo</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>