import { defineStore } from "pinia";
import axios from "axios";

export const useCategoryStore = defineStore("category", {
    state: () => ({
        categories: []
    }),
    getters: {
        getCategories(state) {
            return state.categories
        }
    },
    actions: {
        async fetchCategories() {
            try
            {
                const categories = await axios.get('api/categories')
                this.categories = categories.data.data
                console.log("Categories List ::", categories.data.data)
            } catch(error) {
                console.log(error)
            }
        }
    }
})





// import { defineStore } from 'pinia';
// import axios from 'axios'
// export const useCategoriesStore = defineStore('categories', {
//     state: () => ({
//         categories: [],
//     }),
//     getters: {
//     },
//     actions: {
//         async allCategories() {
//             let res = await axios.get('api/categories');
//             this.categories = res.data.data;
//             console.log("resrsses ", this.categories)
//         },
//     },
// });