<template>
    <section class="categories-cards section-padding-two">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="section-title">
                        <h2>Most Popular Category On Amazon</h2>
                        <p>make your choice </p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-sm-6" v-for="category in categories" :key="category.id">
                    <div class="category-single category--img">
                        <figure class="category--img4">
                            <img :src="category.image" width="350" height="280" alt="Category Image">
                            <figcaption class="overlay-bg">
                                <a href="#" class="cat-box">
                                    <div>
                                        <div class="icon"> 
                                            <span class="la la-arrows"></span> 
                                        </div>
                                        <h4 class="cat-name">{{ category.name }}</h4>
                                        <span class="badge badge-pill badge-success">{{ category.products_count }} Listings</span>
                                    </div>
                                </a>
                            </figcaption>
                        </figure>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
import { useCategoryStore } from '@/store/category-store';
import { computed, onMounted } from 'vue';
// import { storeToRefs } from 'pinia';

const categoryStore = useCategoryStore();

const categories = computed(() => {
    return categoryStore.categories;
});
onMounted(() => {
    categoryStore.fetchCategories();
});



// productStore.fetchProducts();
// categoryStore.fetchCategories()
// const { getCategories } = useCategoryStore();
// allCategories();

</script>