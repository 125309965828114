<template>
    <nav class="navbar navbar-expand-lg mainmenu__menu">
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#direo-navbar-collapse"
            aria-controls="direo-navbar-collapse" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon icon-menu">
                <i class="la la-reorder"></i>
            </span>
        </button>
        <div class="collapse navbar-collapse" id="direo-navbar-collapse">
            <ul class="navbar-nav">
                <li> <router-link to="/">Home</router-link> </li>
                <li> <router-link to="/categories">Categories</router-link> </li>
                <!-- <li> <a href="#">Products</a> </li> -->
                <li> <router-link to="/about">About</router-link> </li>
            </ul>
        </div>
    </nav>
</template>