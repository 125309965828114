<template>
    <section class="listing-cards section-bg section-padding">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="section-title">
                        <h2>Best Porducts Listings Around the World</h2>
                        <p>Explore the popular listings around the world</p>
                    </div>
                </div>
                <div class="listing-cards-wrapper col-lg-12">
                    <div class="row">
                        <div class="col-lg-4 col-sm-6" v-for="product in products.data" :key="product.id">
                            <div class="atbd_single_listing ">
                                <article class="atbd_single_listing_wrapper">
                                    <figure class="atbd_listing_thumbnail_area">
                                        <div class="atbd_listing_image"> 
                                            <a href=""> 
                                                <img :src="product.image" style="height: 200px;" alt="product image" />
                                            </a> 
                                        </div>
                                        <div class="atbd_author atbd_author--thumb"> 
                                            <a href=""> 
                                                <img :src="product.category.image" style="width: 40px; height: 40px;" alt="Category Image"> 
                                                <span class="custom-tooltip">{{ product.category.name }}</span> 
                                            </a> 
                                        </div>
                                        <div class="atbd_thumbnail_overlay_content">
                                            <ul class="atbd_upper_badge">
                                                <li><span class="atbd_badge atbd_badge_featured">{{ product.feature ? 'Featured' : ''}}</span></li>
                                            </ul>
                                        </div>
                                    </figure>
                                    <div class="atbd_listing_info">
                                        <div class="atbd_content_upper">
                                            <h4 class="atbd_listing_title"> 
                                                <span>{{ product.name }}</span>
                                            </h4>
                                            <div class="atbd_listing_meta"> 
                                                <span class="atbd_meta atbd_listing_rating">
                                                    {{ product.ratings }}
                                                    <i class="la la-star"></i>
                                                </span> 
                                                <span class="atbd_meta atbd_listing_price">${{ product.price }}</span> 
                                                <span class="atbd_meta atbd_badge_open">
                                                    <a :href="product.referral_link" target="_blank" rel="noopener noreferrer">Go Shop</a>
                                                </span> 
                                            </div>
                                            <div class="atbd_listing_data_list">
                                                <ul>
                                                    <li>
                                                        <p>
                                                            <span class="la la-map-marker"></span>
                                                            Los Angeles, United States
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <span class="la la-phone"></span>
                                                            (415) 796-3633
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <span class="la la-calendar-check-o"></span>
                                                            {{ product.created_at }}
                                                        </p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="atbd_listing_bottom_content">
                                            <div class="atbd_content_left">
                                                <div class="atbd_listing_category"> 
                                                    <a href="">
                                                        <span class="la la-glass"></span>
                                                        {{ product.category.name }}
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="atbd_content_right">
                                                <li class="atbd_count"><span class="la la-list"></span>{{ product.quantity }}</li>
                                                <!-- <li class="atbd_save"> <span class="la la-heart-o"></span> </li> -->
                                            </ul>
                                        </div>
                                    </div>
                                </article>
                            </div>
                        </div>
                        <div class="col-lg-12 text-center m-top-20">
                            <router-link to="/categories"><a href="" class="btn btn-gradient btn-gradient-two">Explore More</a></router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
import { useProductStore } from '@/store/product-store';
// import { storeToRefs } from 'pinia';
import { computed, onMounted } from 'vue';

const productStore = useProductStore();
const products = computed(() => {
    return productStore.products
})

onMounted(() => {
    productStore.fetchProducts();
})

</script>