import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../pages/HomeView.vue'
import AboutView from '../pages/AboutView.vue'
import ErrorView from '@/pages/ErrorView.vue'
import CategoryListingView from '../pages/CategoryListingView.vue'

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/about',
        name: 'about',
        component: AboutView
    },
    {
        path: '/categories',
        name: 'categories',
        component: CategoryListingView
    },
    {
        path: '/:catchAll(.*)',
        name: 'error',
        component: ErrorView
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router;





// const router = createRouter({
//     history: createWebHistory(),
//     routes : [
//         {
//             path: '/',
//             name: 'home',
//             component: HomeView
//         },
//         {
//             path: '/about',
//             name: 'about',
//             component: AboutView
//         },
//         {
//             path: '/categories',
//             name: 'categories',
//             component: CategoryListingView
//         },
//         {
//             path: '/:catchAll(.*)',
//             name: 'error',
//             component: ErrorView
//         },
//     ]
// });







// {
//     path: '/account',
//     name: 'account',
//     component: () => import('../views/AccountView.vue'),
//     children: [
//         {
//             path: 'profile',
//             name: 'ProfileSection',
//             component: () => import('../views/account/ProfileSection.vue'),
//         },
//     ]
// }