import { defineStore } from 'pinia'
import axios from "axios"

export const useProductStore = defineStore("product", {
    state: () => ({
        products: [],
        total: ''
    }),
    getters: {
        getProducts(state) {
            return state.products
        },
        filteredProducts: (state) => ({ category, search, feature, rating }) => {

            return (!category && !search && !feature && !search)
                ? state.products.data
                : state.products.data.filter((product) => {
                    const categoryMatch = !category || product.category_id === category;

                    const searchMatch = !search || product.name.toLowerCase().includes(search.toLowerCase());

                    const featureMatch = !feature || product.feature == feature;

                    const ratingMatch = !rating || product.ratings == rating;

                    return categoryMatch && searchMatch && featureMatch && ratingMatch;
                });
        },
    },
    actions: {
        async fetchProducts(page = 1, category = '', search = '', feature = '', rating = '', order_column = '', order_direction = '') {
            try {
                // const  page = 1;
                const products = await axios.get(`api/products?page=${page}&category=${category}&search=${search}&feature=${feature}&rating=${rating}&order_column=${order_column}&order_direction=${order_direction}`)
                this.products = products.data
                this.total = products.data.meta.total
                // this.meta = products.data
                // this.link = products.data.links

                console.log("Product List ::", products.data)
                
                // return this.products;
            }
            catch (error) {
                console.log(error)
            }
        }
        // async fetchProducts() {
        //     try {
        //         const products = await axios.get('api/products')
        //         this.products = products.data
        //         this.meta = products.data.meta
        //         this.link = products.data.links
        //         console.log("Product List ::", products.data)
        //     }
        //     catch (error) {
        //         console.log(error)
        //     }
        // }
    },
})


// import { defineStore } from 'pinia';
// import axios from 'axios'
// export const useProductsStore = defineStore('products', {
//     state: () => ({
//         products: [],
//     }),

//     getters: {

//     },
//     actions: {
//         async allProducts() {
//             let res = await axios.get('api/products');
//             this.products = res.data.data;
//             console.log("resrsses ", this.products)
//         },
//     },
// });